import Layout4 from "../pages/Layout4"
import PrivacyPolicy from "../pages/PrivacyPolicy";


const blankRoutes = [
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
];

const defaultRoutes = [
  { path: "/", component: <Layout4 /> },
];

export { blankRoutes, defaultRoutes };